<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_routeSlip" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item" style="float: left; width: 450px">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                :rules="[rules.supplierRequired]"
                :error-messages="alertSupplierMessage"
                @change="getSuppliersSelected"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <span class="item-spacer">&nbsp;</span>
            <!-- カレンダー -->
            <!-- 出荷予定日From-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="shippingScheduleDateFrom"
                :label="$t('label.lbl_scheduleDate') + '（From）'"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="shippingScheduleDateFromCal"
                  @input="dateMenuFrom = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- カレンダーの間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷予定日To-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="shippingScheduleDateTo"
                :label="$t('label.lbl_scheduleDate') + '（To）'"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              >
              </v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="shippingScheduleDateToCal"
                  @input="dateMenuTo = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row>
            <!-- 路線会社-->
            <div class="search-autocomplete first-search-item" style="float: left; width: 450px">
              <v-autocomplete
                dense
                v-model="truckSelected"
                :items="deliveryTraderList"
                :rules="[rules.route]"
                :label="$t('label.lbl_routeslipcompany')"
                :error-messages="alertRouteMessage"
                :hint="setTruck()"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 発行条件-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="issueTermsSelected"
                :items="issueTermsList"
                :label="$t('label.lbl_issueTerms')"
                :hint="setTagTerms()"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 路線伝票フォーマット -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="invoiceTemplateSelected"
                :items="invoiceTemplateList"
                :label="$t('label.lbl_invoiceTemplate')"
                :hint="setInvoiceTemplate()"
                :error-messages="alertRouteSlipFormatMessage"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="getApiList(true)">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-search-area" style="float: right">
                <!-- CSV抽出 -->
                <v-btn color="primary" class="api-btn" @click="routeCsv">{{
                  $t("btn.btn_routeCsv")
                }}</v-btn>
                <!-- 路線伝票発行ボタン -->
                <!-- <v-btn
                  color="primary"
                  class="api-btn"
                  @click="routeSlipIssue"
                  :disabled="
                    chengeListCheckbox.length == 0 && listCheckboxBtn.length == 0 && !isAllSelected
                  "
                  >{{ $t("btn.btn_routeSlipIssue") }}</v-btn
                > -->
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
            <!-- ページング数 -->
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="530px"
          :options.sync="sortOptions"
        >
          <!-- 出荷No -->
          <template v-slot:[`item.shipNo`]="{ item }">
            <div class="listNo-style">{{ item.shipNo }}</div>
          </template>

          <!-- 出荷先 -->
          <template v-slot:[`item.shippingNm`]="{ item }">
            <a @click="openRouteDitail(item)" style="text-decoration: underline">
              <div>{{ item.shippingNm }}</div>
            </a>
          </template>
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              @click="checkList(item, index)"
              style="transform: scale(2)"
            />
          </template>
          <!--詳細マーク-->
          <template v-slot:[`item.detail`]="{ item, index }">
            <v-btn small @click="editdetail(item, index)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 詳細設定モーダル -->
      <v-dialog v-model="isOpenEditDetailDialog" :max-width="1300" persistent>
        <editDetailDateDialog
          :editDetailDateDialog.sync="isOpenEditDetailDialog"
          :viewDataList="inputList"
          :selectedIndex="selectedIndex"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.SCREEN_ID.P_ROU_001,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    u_10: false,
    involce: "",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [],
    // 輸送会社データ項目
    truckInfo: { truck: "" },
    // ロケーションダイアログ
    dialogLocation: false,
    // 出荷予定日From
    shippingScheduleDateFromCal: "",
    shippingScheduleDateFrom: "",
    // 出荷予定日To
    shippingScheduleDateToCal: "",
    shippingScheduleDateTo: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 出荷先
    shippingSelected: "",
    // 取引先
    suppliersSelected: "",
    // 発行条件(送り状)
    issueTermsSelected: "",
    // 発行条件(荷札)
    tagTermsSelected: "",
    // 検索条件保持用
    searchSuppliersSelected: "",
    searchShippingScheduleDateFrom: "",
    searchShippingScheduleDateTo: "",
    searchDeliveryTraderSid: "",
    searchIssueTermsSelected: "",
    searchTagTermsSelectedNm: "",
    // 選択行
    selectedIndex: 0,
    length: 0,
    // メニュー
    openMenu: null,
    isOpenEditDetailDialog: false,
    // 出荷予定Fromカレンダー
    dateMenuFrom: false,
    // 出荷予定Toカレンダー
    dateMenuTo: false,
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckboxBtn: [],
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    // 荷札発行枚数ダイアログ
    numberIssuesDialog: false,
    // 荷札発行枚数
    numIssue: "",
    // 取引先エラーメッセージ
    alertSupplierMessage: "",
    // 路線伝票フォーマットエラーメッセージ
    alertRouteSlipFormatMessage: "",
    // 路線伝票エラーメッセージ
    alertRouteMessage: "",
    //帳票テンプレート選択値
    invoiceTemplateSelected: null,
    // 路線伝票
    truckSelected: "",
    deliveryTraderList: [],
    deliveryTraderMap: {},
    //帳票テンプレートリスト
    invoiceTemplateList: [],
    isRouteSearch: false,
    // 初期データ
    defaultData: [],
    // クリアフラグ
    clearFlg: false,
    // ヘッダ
    headerItems: [
      { text: "選択", value: "check", width: "3%", align: "center", sortable: false },
      // 出荷No
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "shipNo",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 出荷先
      {
        text: i18n.tc("label.lbl_destination"),
        value: "shippingNm",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 出荷日
      {
        text: i18n.tc("label.lbl_scheduleDate"),
        value: "shippingScheduleDateJ",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 状況
      {
        text: i18n.tc("label.lbl_Situatuion"),
        value: "taglabel",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_detail"),
        value: "detail",
        width: "7%",
        align: "center",
        sortable: false,
      },
    ],
    // CSV出力URL
    returnURL: "",
    // 修正削除画面
    detailDialogData: {},
    // 総件数
    totalCount: 0,
    // 一覧
    inputList: [],
    // 取引先プルダウン
    supplierList: [],
    // 出荷先プルダウン
    shippingList: [],
    // 発行条件プルダウン
    issueTermsList: [],
    //遷移している場合
    datackeck: 0,
    datackeck1: 0,

    // 輸送会社名（画面表示用）
    truckNm: "",
    // 輸送会社区分（登録用）
    truckKbn: "",
    // 輸送会社名（登録用）
    truck: "",
    // 荷札発行権限
    tagExportRole: "true",
    // フォーマット処理実行フラグ
    formartFlg: false,
    // 入力チェック
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      route: (value) => !!value || i18n.tc("check.chk_inputRoute"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
    },
  }),
  created() {},

  methods: {
    append() {
      this.truckKbn = "";
    },
    link() {
      location.href = this.returnURL;
    },

    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.getApiList(false);
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false);
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        console.log(this.listCheckboxBtn);
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
          if (this.listCheckboxBtn.indexOf(this.inputList[i]) == -1) {
            this.listCheckboxBtn.push(this.inputList[i]);
            if (this.chengeListCheckbox.indexOf(this.inputList[i]) == -1) {
              this.chengeListCheckbox.push(this.inputList[i]);
            }
          }
        }
        this.length = this.inputList.length;
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
          this.inputList[j].check = false;
          for (var b = 0; b < this.chengeListCheckbox.length; b++) {
            if (this.chengeListCheckbox[b].shipSid != this.inputList[j].shipSid) {
              this.listCheckboxBtn.push(this.chengeListCheckbox[b]);
            }
          }
          this.chengeListCheckbox = this.listCheckboxBtn;
        }
        this.isAllSelected = false;
        this.length = 0;
      }
    },

    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];

          // 路線伝票・フォーマット画面から戻る場合

          if (this.$route.params.searchSuppliersSelected || this.$route.params.searchConditions) {
            this.getSuppliersSelected();
          }

          if (
            this.$route.params.searchSuppliersSelected ||
            this.$route.params.routeSlipDetailData
          ) {
            this.getSuppliersSelected();
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 発行条件（送り状・荷札）
      const termsList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_INVOICE_DIV);

      Promise.all([termsList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.issueTermsList = result[0];
          for (var i = 0; i < this.issueTermsList.length; i++) {
            if (this.issueTermsList[i].value == "01") {
              this.issueTermsList[i].name = "発行済";
              this.issueTermsList[i].text = "発行済";
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 出荷先取得
    getSuppliersSelected() {
      //路線会社List初期化
      this.deliveryTraderList = [];
      if (this.datackeck1 == "1") {
        this.datackeck1 = 0;
      } else {
        this.truckSelected = "";
      }
      if (this.suppliersSelected == null || this.suppliersSelected == "") {
        this.shippingList = [];
        this.shippingSelected = "";
        return;
      }
      this.loadingCounter = 1;
      //路線会社List呼び出し
      this.getDeliveryTrader();

      // 選択した会社コードを取得
      let officeCd = this.supplierList.find(
        (element) => element.value === this.suppliersSelected
      ).clientSid;
      // To（出荷先）取得
      const fromList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.FROMTO,
        dateTimeHelper.convertUTC(),
        undefined,
        officeCd
      );
      Promise.all([fromList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.shippingList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 荷札発行枚数ダイアログ、とじる
     */
    numIssueClose() {
      this.$refs.numIssueVali.resetValidation();
      this.numIssue = "";
      this.numberIssuesDialog = false;
    },

    /**
     * 荷札発行枚数ダイアログ、登録
     */
    numIssueSave() {
      // 荷札発行API実行
      this.getTagIssue();
      this.numberIssuesDialog = false;
      this.$refs.numIssueVali.resetValidation();
      this.numIssue = "";
    },

    /**
     * 路線伝票画面： 検索ボタン押下処理
     */
    getApiList(checkBoxFlag) {
      // 画面クリア時の場合
      if (this.clearScreenFlg) {
        this.clearScreenFlg = false;
        return;
      }
      this.isRouteSearch = this.truckNm == i18n.tc("label.lbl_route");
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      // メッセージ初期化
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      this.alertRouteMessage = "";
      // 取引先存在チェック
      if (
        (this.truckSelected == "" || this.truckSelected == null) &&
        (this.suppliersSelected == "" || this.suppliersSelected == null)
      ) {
        this.alertRouteMessage = i18n.tc("check.chk_inputRoute");
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        return;
      }
      if (
        // FromToの日付、入力チェック
        new Date(this.shippingScheduleDateFrom).getTime() >
        new Date(this.shippingScheduleDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else {
        /**
         * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
         * エラーがでるので回避するためこのようなコードとなっている。
         */
        if (
          ((this.$route.params.searchSuppliersSelected || this.$route.params.searchConditions) &&
            this.checkFlg) ||
          ((this.$route.params.searchSuppliersSelected || this.$route.params.routeSlipDetailData) &&
            this.checkFlg)
        ) {
          // 登録画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else {
          // 上記外の場合はチェックを行う。
          inputCheckResult = this.$refs.form.validate();
        }
        // 画面遷移フラグをfalseに戻す
        this.checkFlg = false;

        if (inputCheckResult) {
          // バリデーションエラーメッセージを非表示
          this.alertMessage = "";
          // 初期検索かページング検索か判断
          if (checkBoxFlag) {
            this.isAllSelected = false;
          }

          // ローディング画面表示ON
          this.loadingCounter = 1;

          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();
          // 営業所SID（ログインユーザ情報）
          config.params.officeSid = sessionStorage.getItem("sales_office_sid");
          // 路線画面の場合
          config.params.isRouteSlip = "1";
          // 取引先SID
          config.params.clientSid = this.suppliersSelected;
          // From日
          config.params.outScheduleDateFrom = dateTimeHelper.convertUTC(
            this.shippingScheduleDateFrom
          );
          // To日
          var arr = this.shippingScheduleDateTo + " 23:59:59.999";
          config.params.outScheduleDateTo = dateTimeHelper.convertUTC(arr);
          // 出荷先
          if (this.shippingSelected) {
            // ToSID（出荷先SID）
            config.params.toSid = this.shippingSelected;
          }
          // 路線会社
          if (this.truckSelected != "") {
            config.params.deliveryTraderSid = this.truckSelected;
          }
          if (this.issueTermsSelected) {
            // 発行条件（送り状）
            config.params.invoicePrintCd = this.issueTermsSelected;
          }
          if (this.tagTermsSelected) {
            // 発行条件（荷札）
            config.params.taglabelPrintCd = this.tagTermsSelected;
          }

          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
          // ページング
          config.params.reqComPaginationFlg = "1";
          config.params.reqComPageLimit = this.itemsPerPage;

          if (this.sortItem != "") {
            config.params.reqComSortItem = this.sortItem;
          }
          // 検索条件保持用
          this.searchShippingScheduleDateFrom = this.shippingScheduleDateFrom;
          this.searchSuppliersSelected = this.suppliersSelected;
          this.searchShippingScheduleDateTo = this.shippingScheduleDateTo;
          this.searchDeliveryTraderSid = this.truckSelected;
          this.searchIssueTermsSelected = this.issueTermsSelected;

          // console.debug("getApiList(checkBoxFlag) Config", config);
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.ROUTE_SLIP_OUTS_CHEDULES, config)
              .then((response) => {
                // console.debug("getApiList(checkBoxFlag) Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // 成功
                if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                  this.pageCount = jsonData.resCom.resComPagination.totalPage;
                  this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                  const list = [];
                  let involceIn = "";
                  let exited = "";
                  // 明細リストを取得
                  jsonData.resIdv.outSchedules.forEach((row) => {
                    if (row.invoicePrintDate) {
                      // 状態（送り状）あり
                      involceIn = i18n.tc("label.lbl_printed");
                    } else {
                      // 状態（送り状）なし
                      involceIn = i18n.tc("label.lbl_notPrint");
                    }
                    if (
                      row.wareOutOrderComplete == 0 ||
                      row.wareOutOrderCnt > row.wareOutOrderComplete
                    ) {
                      // 未出庫
                      exited = i18n.tc("label.lbl_unshipped");
                    } else {
                      // 出庫済
                      exited = i18n.tc("label.lbl_shipped");
                    }

                    list.push({
                      // 出荷日（API登録用=UTC）
                      shippingScheduleDate: row.outScheduleDate,
                      // 出荷日（画面表示用=JST:秒数なし）
                      shippingScheduleDateJ: dateTimeHelper
                        .convertUTC2JST(row.outScheduleDate)
                        .substr(0, 10),
                      // 出荷SID
                      shipSid: row.outListSid,
                      // 出荷No
                      shipNo: row.outListNo,
                      // 有償/無償
                      isPaid: row.freeCostDivName,
                      // 有償無償名
                      freeCostDivName: row.freeCostDivName,
                      // 状態（送り状）・状況
                      taglabel: involceIn,
                      // 状態（荷札）
                      // taglabel: taglabelIn,
                      // 処理区分名
                      processingdivision: row.processDivName,
                      // 出荷先
                      shippingNm: row.toName,
                      // 出荷先SID
                      toSid: row.toSid,
                      // 輸送会社名
                      truck: row.carrierCompCdName,
                      // 輸送会社区分
                      carrierCompCd: row.carrierCompCd,
                      // 輸送会社種別名
                      carrierCompName: row.carrierCompName,
                      // 備考
                      remarks: row.remarks,
                      // 出庫済
                      exted: exited,
                      // 路線業者SID
                      traderSid: row.deliveryTraderSid,
                      // 路線業者名
                      deliveryTraderName: row.deliveryTraderName,
                      // 品質区分
                      qualityDiv: row.qualityDiv,
                      // 責任区分
                      blameDiv: row.blameDiv,
                      // チェックボックス
                      check: false,
                      // 出荷予定明細
                      dtlList: row.outScheduleDtl,
                      // 完了フラグ
                      completeFlg: row.completeFlg,
                      // 更新日時
                      updateDatetime: row.updateDatetime,
                      // 出荷指示一覧フラグ
                      isOutOrder: row.isOutOrder,
                      // お届け予定日
                      deliveryDate: row.deliveryDate,
                      // お届け先
                      address: row.address,
                      // 輸送会社CD
                      carrierCompCd7: row.carrierCompCd7,
                    });
                  });
                  this.inputList = list;
                  this.length = 0;
                  if (!checkBoxFlag) {
                    // 検索ボタン以外で検索処理が走った場合、元々チェックしていたレコードにチェックをいれる
                    for (var k = 0; k < this.inputList.length; k++) {
                      for (var j = 0; j < this.listCheckboxBtn.length; j++) {
                        if (this.inputList[k].shipSid == this.listCheckboxBtn[j].shipSid) {
                          this.inputList[k].check = true;
                          this.length++;
                        }
                      }
                    }
                    if (this.length == this.inputList.length) {
                      this.isAllSelected = true;
                    } else {
                      this.isAllSelected = false;
                    }
                  } else {
                    // 検索ボタン押下時は、チェックボックスを詰めていたListを初期化する
                    this.listCheckboxBtn.length = 0;
                  }
                  console.log(this.listCheckboxBtn.length);
                  // ページング検索の場合の全選択チェックボックス処理
                  // if (this.isAllSelected) {
                  //   this.isAllSelected = true;
                  //   for (var i = 0; i < this.inputList.length; i++) {
                  //     this.inputList[i].check = true;
                  //   }
                  // }
                  resolve(response);
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              })
              .then(() => {
                // 明細単位で出庫指示の有無を確認していく（一部出庫指示がない（未引当）けど、一部出庫指示があるパターン）
                for (let i = 0; i < this.inputList.length; i++) {
                  var cnt = 0;
                  for (let j = 0; j < this.inputList[i].dtlList.length; j++) {
                    if (this.inputList[i].dtlList[j].isAllOrder == 0) {
                      // 出庫指示がないレコードをカウントする
                      cnt = cnt + 1;
                    }
                  }

                  // if (this.inputList[i].dtlList.length != cnt) {
                  //   // 明細の数と出庫指示がない（未引当）の数が一致しなかったら、ステータスを「出庫中（一部未引当・出庫実績あり）」とする
                  //   this.inputList[i].exted = i18n.tc("label.lbl_shippedNow");
                  // }
                }
              });
          });
        }
      }
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 路線伝票画面： 荷札発行API
     */
    getTagIssue() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();

      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_003;
      // タイムゾーン
      body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
      // 荷札情報List
      var bodyList = [];

      for (var i = 0; i < this.listCheckboxBtn.length; i++) {
        var inputMap = {};
        // 出荷伝票SID
        inputMap.outListSid = this.listCheckboxBtn[i].shipSid;

        // 荷札明細
        this.taglabelDtls = [];
        for (var j = 0; j < this.listCheckboxBtn[i].dtlList.length; j++) {
          var tagDtlMap = {};
          // 出荷予定明細SID
          tagDtlMap.outListSubSid = this.listCheckboxBtn[i].dtlList[j].outListSubSid;
          // 荷札発行枚数
          tagDtlMap.taglabelCnt = this.numIssue;
          this.taglabelDtls.push(tagDtlMap);
        }
        inputMap.taglabelDtls = this.taglabelDtls;
        bodyList.push(inputMap);
      }

      body.reqIdv.taglabel = bodyList;

      // console.debug("getTagIssue() Body", body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_TAGLABEL, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("getTagIssue() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 成功
            if (jsonData.resCom.resComCode == "000") {
              // 返却されたurlをデータに格納、ダウンロードボタンの活性化
              this.returnURL = jsonData.resIdv.filePath;
              this.link();
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            console.error("getTagIssue() Resolve", resolve);

            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      if (this.suppliersSelected) {
        // console.debug(this.suppliersSelected);
      } else {
        this.shippingSelected = "";
        this.suppliersSelected = "";
        this.shippingScheduleDate = "";
        this.truckNm = "";
        this.inputList = [];
        this.itemsPerPage = 10;
        this.itemsPerPageBtn = "10";
        this.shippingScheduleDateTo = dateTimeHelper.convertJST();
        this.shippingScheduleDateFrom = dateTimeHelper.convertJST();
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      }

      // 荷札発行権限を取得
      this.tagExportRole = sessionStorage.getItem("P-SHP-003_TAG");
      // 明細画面から遷移される場合
      let detailSearchParam = this.$route.params.routeSlipDetailData;
      if (detailSearchParam != null && !this.clearFlg) {
        this.suppliersSelected = detailSearchParam.searchSuppliersSelected;
        this.truckSelected = detailSearchParam.searchDeliveryTraderSid;
        this.datackeck = 1;
        this.datackeck1 = 1;
        this.formartFlg = true;
        this.alertSupplierMessage = detailSearchParam.alertSupplierMessage;
        this.shippingScheduleDateFrom = detailSearchParam.searchShippingScheduleDateFrom;
        this.shippingScheduleDateTo = detailSearchParam.searchShippingScheduleDateTo;
        this.shippingSelected = detailSearchParam.shippingSelected;
        this.truckKbn = detailSearchParam.truckKbn;
        this.truckNm = detailSearchParam.truckNm;
        this.issueTermsSelected = detailSearchParam.searchIssueTermsSelected;
        this.tagTermsSelected = detailSearchParam.searchTagTermsSelected;
        this.invoiceTemplateSelected = detailSearchParam.invoiceTemplateSelected;
        this.listCheckboxBtn = detailSearchParam.listCheckboxBtn;
        this.checkFlg = true;
        this.changeDateTo(this.shippingScheduleDateTo);
        this.changeDateFrom(this.shippingScheduleDateFrom);
        this.getApiList(false);
        this.isAllSelected = detailSearchParam.isAllSelected;
        // 遷移フラグ
        this.transitionFlg = true;
      }
      // フォーマット別追加情報入力から遷移された場合
      let formatViewParam = this.$route.params.searchConditions;
      if (formatViewParam != null && !this.clearFlg) {
        this.datackeck = 1;
        this.datackeck1 = 1;
        this.suppliersSelected = formatViewParam.searchSuppliersSelected;
        this.truckSelected = formatViewParam.searchDeliveryTraderSid;
        this.formartFlg = true;
        this.alertSupplierMessage = formatViewParam.alertSupplierMessage;
        this.shippingScheduleDateFrom = formatViewParam.searchShippingScheduleDateFrom;
        this.shippingScheduleDateTo = formatViewParam.searchShippingScheduleDateTo;
        this.shippingSelected = formatViewParam.shippingSelected;
        this.truckKbn = formatViewParam.truckKbn;
        this.truck = formatViewParam.truck;
        this.issueTermsSelected = formatViewParam.searchIssueTermsSelected;
        this.tagTermsSelected = formatViewParam.searchTagTermsSelected;
        this.invoiceTemplateSelected = formatViewParam.invoiceTemplateSelected;
        this.listCheckboxBtn = formatViewParam.listCheckboxBtn;
        this.checkFlg = true;
        this.truckInfo = formatViewParam.truckInfo;
        this.truckNm = formatViewParam.truckNm;
        this.changeDateTo(this.shippingScheduleDateTo);
        this.changeDateFrom(this.shippingScheduleDateFrom);
        this.getApiList(false);
        this.isAllSelected = formatViewParam.isAllSelected;
        // 遷移フラグ
        this.transitionFlg = true;
      }
      this.getCustomInfo();
      this.getMstCode();
      // this.getDeliveryTrader();
      // this.routeFormat();
    },

    // 路線伝票明細画面へ
    openRouteDitail(item) {
      // 選択した出荷先
      this.editIndex = this.inputList.indexOf(item);
      this.getTextName(item.shipSid);
      // 明細画面に渡す値
      this.$router.push({
        name: appConfig.SCREEN_ID.P_SHP_007,
        params: {
          routeSlipDetailData: {
            alertSupplierMessage: this.alertSupplierMessage,
            issueTermsSelected: this.issueTermsSelected,
            tagTermsSelected: this.tagTermsSelected,
            invoiceTemplateSelected: this.invoiceTemplateSelected,
            listCheckboxBtn: this.listCheckboxBtn,
            backpage: "RouteSlipDetail", // 画面名
            // 出荷伝票SID
            outListSid: item.shipSid,
            // 出荷No
            shippingNo: item.shipNo,
            // 明細一覧
            dtlList: item.dtlList,
            // 出荷先
            shippingNm: item.shippingNm,
            // 出荷日
            shippingScheduleDateJ: item.shippingScheduleDateJ,
            // 輸送会社名
            truckNm: this.truckNm,
            // 発行条件
            taglabel: item.taglabel,
            // 取引先Name
            supplierNm: this.suppliersNm,
            // 出荷先SID
            shippingSelected: item.shippingSelected,
            // チェックボックスヘッダー
            isAllSelected: this.isAllSelected,
            // お届け予定日
            deliveryDate: item.deliveryDate,
            // 輸送会社CD
            carrierCompCd7: item.carrierCompCd7,
            // 検索条件保持用
            searchSuppliersSelected: this.searchSuppliersSelected,
            searchShippingScheduleDateFrom: this.searchShippingScheduleDateFrom,
            searchShippingScheduleDateTo: this.searchShippingScheduleDateTo,
            searchDeliveryTraderSid: this.searchDeliveryTraderSid,
            searchIssueTermsSelected: this.searchIssueTermsSelected,
          },
        },
      });
    },

    // 次画面表示用の名称取得
    getTextName(shipSid) {
      // 取引先名と取引先コードを取得
      for (var k = 0; k < this.supplierList.length; k++) {
        if (this.supplierList[k].value == this.searchSuppliersSelected) {
          this.suppliersNm = this.supplierList[k].text;
        }
      }
      for (var i = 0; i < this.deliveryTraderList.length; i++) {
        if (this.deliveryTraderList[i].value == this.searchDeliveryTraderSid) {
          this.truckNm = this.deliveryTraderList[i].text;
        }
      }

      //出庫先を取得
      for (var j = 0; j < this.shippingList.length; j++) {
        if (this.shippingList[j].value == shipSid) {
          this.shippingNm = this.shippingList[j].text;
        }
      }
    },

    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合
        if (this.chengeListCheckbox.indexOf(item) == -1) {
          // 編集用リスト
          this.chengeListCheckbox.push(item);
        }
        if (this.listCheckboxBtn.indexOf(item) == -1) {
          // 画面遷移用リスト
          this.listCheckboxBtn.push(item);
        }
        this.length++;
        //全チェックされているか確認
        if (this.inputList.length == this.length) {
          this.isAllSelected = true;
        }
      } else {
        // チェックを外した場合

        // 外したレコードの出荷SIDを取得
        let inputListOutlistNo = this.inputList[index].shipSid;
        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.chengeListCheckbox.length; i++) {
          if (this.chengeListCheckbox[i].shipSid != inputListOutlistNo) {
            this.listCheckboxBtn.push(this.chengeListCheckbox[i]);
          }
        }

        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.chengeListCheckbox.push(this.listCheckboxBtn[j]);
        }
        this.length--;
        // if (this.listCheckboxBtn.length == 0 && this.chengeListCheckbox.length == 0) {
        this.isAllSelected = false;
        // }
      }
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    /**
     * 出荷日付Fromを－１日します。
     */
    prevDate() {
      if (this.shippingScheduleDateFromCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateFromCal != toDate) {
        this.shippingScheduleDateFromCal = toDate;
      } else {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() - 1)
        );
      }
    },

    /**
     * 出荷日付Fromを＋１日します。
     */
    nextDate() {
      if (this.shippingScheduleDateFromCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateFromCal != toDate) {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 2)
        );
      } else {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 1)
        );
      }
    },

    /**
     * 出荷日付Fromを－１日します。
     */
    prevToDate() {
      if (this.shippingScheduleDateToCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateToCal != toDate) {
        this.shippingScheduleDateToCal = toDate;
      } else {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 出荷日付Fromを＋１日します。
     */
    nextToDate() {
      if (this.shippingScheduleDateToCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateToCal != toDate) {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shippingScheduleDateFromCal = null;
      }
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shippingScheduleDateToCal = null;
      }
    },

    /**
     * 路線伝票画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 路線伝票画面：出荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      for (var i = 0; i < this.shippingList.length; i++) {
        if (this.shippingList[i].value == this.shippingSelected) {
          return this.shippingList[i].name;
        }
      }
    },

    /**
     * 発行条件リストボックス変換
     */
    setTerms() {
      for (var i = 0; i < this.issueTermsList.length; i++) {
        if (this.issueTermsList[i].value == this.issueTermsSelected) {
          return this.issueTermsList[i].name;
        }
      }
    },
    // 発行条件
    setTagTerms() {
      for (var i = 0; i < this.issueTermsList.length; i++) {
        if (this.issueTermsList[i].value == this.tagTermsSelected) {
          return this.issueTermsList[i].name;
        }
      }
    },
    // 路線会社
    setTruck() {
      for (var i = 0; i < this.deliveryTraderList.length; i++) {
        if (this.deliveryTraderList[i].value == this.truckSelected) {
          return this.deliveryTraderList[i].name;
        }
      }
    },
    // 路線伝票フォーマット取得
    setInvoiceTemplate() {
      for (var i = 0; i < this.invoiceTemplateList.length; i++) {
        if (this.invoiceTemplateList[i].value == this.invoiceTemplateSelected) {
          return this.invoiceTemplateList[i].name;
        }
      }
    },
    // 路線業者マスタAPI（路線会社リストボックス）
    getDeliveryTrader() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.clientSid = this.suppliersSelected;

      config.params.isMst = "0";
      config.params.searchCategory = "0";
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_DELIVERY, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getDeliveryTrader() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.deliveryTrader.forEach((row) => {
                list.push({
                  text: row.deliveryTraderLanguage[0].deliveryTraderName,
                  name: row.deliveryTraderLanguage[0].deliveryTraderName,
                  value: row.deliveryTraderLanguage[0].deliveryTraderSid,
                });
                this.deliveryTraderMap[row.deliveryTraderLanguage[0].deliveryTraderSid] =
                  row.deliveryTraderLanguage[0].deliveryTraderName;
              });
              this.deliveryTraderList = list;

              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getDeliveryTrader() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 詳細ダイアログを表示
     */
    editdetail(item) {
      // 路線会社フォーマットチェック
      if (this.invoiceTemplateSelected == "" || this.invoiceTemplateSelected == null) {
        this.alertRouteSlipFormatMessage = i18n.tc("check.chk_inputRouteSlipFormat");
        return;
      }
      this.isOpenEditDetailDateDialog = true;
      this.getTextName(item.shipSid);
      // フォーマット別追加情報入力遷移
      this.$router.push({
        name: appConfig.SCREEN_ID.P_SHP_010,
        params: {
          searchConditions: {
            alertSupplierMessage: this.alertSupplierMessage,
            shippingSelected: this.shippingSelected,
            issueTermsSelected: this.issueTermsSelected,
            listCheckboxBtn: this.listCheckboxBtn,
            isAllSelected: this.isAllSelected,
            truckNm: this.truckNm,
            shippingNm: item.shippingNm,
            invoiceTemplateSelected: this.invoiceTemplateSelected,
            outListSid: item.shipSid,
            dtlList: item.dtlList,
            deliveryDate: item.deliveryDate,
            address: item.address,
            carrierCompCd7: item.carrierCompCd7,
            // 検索条件保持用
            searchSuppliersSelected: this.searchSuppliersSelected,
            searchShippingScheduleDateFrom: this.searchShippingScheduleDateFrom,
            searchShippingScheduleDateTo: this.searchShippingScheduleDateTo,
            searchDeliveryTraderSid: this.searchDeliveryTraderSid,
            searchIssueTermsSelected: this.searchIssueTermsSelected,
          },
          shippingNo: item.shipNo,
          formatNo: this.setInvoiceTemplate(),
        },
      });
    },
    /**
     * 最新のフォーマットを取得する
     */
    getInvoiceTemplate() {
      if (
        (this.$route.params.searchConditions || this.$route.params.routeSlipDetailData) &&
        this.formartFlg == true
      ) {
        // 処理しない
        this.formartFlg = false;
        return this.invoiceTemplateSelected;
      } else {
        let tmpList = JSON.parse(JSON.stringify(this.invoiceTemplateList));
        let targets = tmpList.sort(function (e1, e2) {
          return e1.priority - e2.priority;
        });
        return targets[0].text;
      }
    },

    /**
     * 路線伝票フォーマット
     */
    routeFormat() {
      if (this.truckSelected) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        // APIパラメータ設定
        const config = this.$httpClient.createGetApiRequestConfig();
        // 営業所SID
        config.params.deliveryTraderSid = this.truckSelected;
        // マスタ取得区分
        config.params.isMst = "1";

        // console.debug("routeFormat() Config", config);

        return new Promise((resolve, reject) => {
          this.$httpClient
            .secureGet(appConfig.API_URL.ROUTE_SLIP_FORMAT_LIST, config)
            .then((response) => {
              // console.debug("routeFormat() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                let list = [{ text: "", priority: 999999999999 }];
                jsonData.resIdv.formatMatch.forEach((row) => {
                  let currentDate = dateTimeHelper.convertJstDateTime(new Date()).getTime();
                  let createDatetime = new Date(row.createDatetime).getTime();
                  let difference = currentDate - createDatetime;
                  list.push({ text: row.formatNo, priority: difference });
                });
                this.invoiceTemplateList = list;
                if (this.truckSelected) {
                  this.invoiceTemplateSelected = this.getInvoiceTemplate();
                }
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;
              }
              resolve(response);
            })
            .catch((resolve) => {
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      } else {
        this.invoiceTemplateList = [];
        this.invoiceTemplateSelected = "";
      }
    },
    /**
     * 路線CSV出力
     */
    routeCsv() {
      if (this.invoiceTemplateSelected == "" || this.invoiceTemplateSelected == null) {
        this.alertRouteSlipFormatMessage = i18n.tc("check.chk_inputRouteSlipFormat");
        return;
      }
      if (this.listCheckboxBtn.length > 0) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        // APIパラメータ
        const config = this.$httpClient.createRequestBodyConfig();
        // 出荷先Sid
        // 路線会社Sid
        const noList = [];
        const noListcheck = [];
        const nocheckList = [];
        // var list = {};
        for (var i = 0; i < this.listCheckboxBtn.length; i++) {
          if (noListcheck.indexOf(this.listCheckboxBtn[i].shipSid) == -1) {
            let list = {};
            list.outListSid = this.listCheckboxBtn[i].shipSid;
            nocheckList.push(this.listCheckboxBtn[i]);
            noListcheck.push(this.listCheckboxBtn[i].shipSid);
            list.deliveryTraderSid = this.truckSelected;
            list.formatNo = this.invoiceTemplateSelected;
            list.carrierCompCd7 = this.listCheckboxBtn[i].carrierCompCd7;
            noList.push(list);
          }
        }

        this.listCheckboxBtn = [];
        this.chengeListCheckbox = [];
        for (var b = 0; b < nocheckList.length; b++) {
          this.listCheckboxBtn.push(nocheckList[b]);
          if (this.chengeListCheckbox.indexOf(nocheckList[b]) == -1) {
            this.chengeListCheckbox.push(nocheckList[b]);
          }
        }
        config.reqIdv.routeSlip = noList;
        // 画面ID
        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_005;

        // console.debug("routeCsv() Config", config);

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.ROUTE_SLIP_CSV, config, appConfig.APP_CONFIG)
            .then((response) => {
              // console.debug("routeCsv() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                console.log("sccess");
                let filePathList = jsonData.resIdv.filePath;
                if (filePathList != null) {
                  this.returnURL = filePathList[0];
                }
                this.link();
                this.getApiList();
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = false;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.bilActualFlg = false;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      } else {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      }
    },
    /**
     * 路線伝票発行 TODO
     */
    // routeSlipIssue() {
    // // ローディング画面表示ON
    // this.loadingCounter = 1;
    // // リクエスト設定
    // const body = this.$httpClient.createRequestBodyConfig();
    // body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_005; // 画面ID
    // body.reqIdv.clientSid = this.suppliersSelected; // 取引先

    // // console.debug("routeSlipIssue() Body", body);

    // return new Promise((resolve, reject) => {
    //   this.$httpClient
    //     // 接続先のAPI_URLを入力
    //     .securePost(appConfig.API_URL.ROUTE_URL, body, appConfig.APP_CONFIG)
    //     .then((response) => {
    //       // console.debug("routeSlipIssue() Response", response);
    //       const jsonData = JSON.parse(JSON.stringify(response.data));

    //       // 正常時
    //       if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
    //         this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
    //           i18n.tc("btn.btn_send"),
    //         ]);
    //         this.infoDialog.title = "結果";
    //         this.infoDialog.isOpen = true;
    //         this.infoDialog.screenFlg = true;
    //         this.infoDialog.routeFlg = true;
    //         resolve(response);
    //         // リストクリア
    //         this.inputList = [];
    //         // 再検索
    //         this.getApiList();
    //       } else {
    //         // エラー時
    //         this.infoDialog.message = jsonData.resCom.resComMessage;
    //         this.infoDialog.title = appConfig.DIALOG.title;
    //         this.infoDialog.isOpen = true;
    //         this.infoDialog.screenFlag = true;
    //         this.infoDialog.routeFlg = false;
    //         reject();
    //       }
    //     })
    //     .catch((ex) => {
    //       this.infoDialog.message = ex;
    //       this.infoDialog.title = appConfig.DIALOG.title;
    //       this.infoDialog.isOpen = true;
    //       this.infoDialog.screenFlag = true;
    //       this.infoDialog.routeFlg = false;
    //       reject();
    //     })
    //     .finally(() => {
    //       // ローディング画面表示OFF
    //       this.loadingCounter = 0;
    //     });
    // });
    //   if (this.$refs.form.validate()) {
    //     // console.debug("Route Csv");
    //     // TODO 路線伝票発行API呼び出し
    //     return;
    //   }
    // },
    // 項目が空かチェック
    isEmpty(value) {
      if (value === undefined || value === null || value === "") {
        return true;
      }
      return false;
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 画面遷移後の場合
      if (this.transitionFlg) {
        // 遷移フラグ
        this.transitionFlg = false;
        // クリアフラグ
        this.clearFlg = true;
      }
      // 変更検知用の設定
      this.clearScreenFlg = true;
      this.shippingScheduleDateFromCal = "";
      this.shippingScheduleDateToCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    suppliersSelected() {
      if (this.datackeck == 1) {
        this.datackeck = 0;
      } else {
        // 路線会社、路線伝票フォーマットクリア
        this.truckSelected = "";
        // this.deliveryTraderList = [];
        this.invoiceTemplateSelected = "";
        this.invoiceTemplateList = [];

        this.alertSupplierMessage = "";
      }
    },
    truckSelected(newValue) {
      if (newValue || null) {
        this.isAllSelected = false;
        this.truckSelected = newValue;
        this.routeFormat();
        this.inputList = [];
        this.totalCount = 0;
        this.pageCount = 0;
        this.alertRouteMessage = "";
      } else {
        this.isAllSelected = false;
        this.truckSelected = newValue;
        this.routeFormat();
        this.inputList = [];
        this.totalCount = 0;
        this.pageCount = 0;
        this.alertRouteMessage = "";
      }
    },
    // truckSelected() {
    //   this.alertRouteMessage = "";
    // },
    invoiceTemplateSelected() {
      this.alertRouteSlipFormatMessage = "";
    },
    shippingScheduleDateFromCal: {
      handler(val) {
        this.shippingScheduleDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    shippingScheduleDateToCal: {
      handler(val) {
        this.shippingScheduleDateTo = this.formatDate(val);
      },
      deep: true,
    },
    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false);
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_SHP_003_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getApiList(false);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.link {
  text-decoration: none;
}

.add-textbox-num {
  width: 16rem;
  padding: 20px 0 0 10px;
}
.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}
</style>
